import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { clearToken } from '../utils/tokenHelper';  // Import the clearToken function

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = () => {
        clearToken();  // Clear the token
        navigate('/login');  // Redirect to the login page
    };

    return (
        <div className='w-1/2 flex flex-col h-screen justify-between items-start p-3 py-6 shadow-2xl
        md:h-screen pb-28 md:w-1/4 lg:w-1/5 lg:h-[520px] lg:h-screen  fixed xl:w-1/6 bg-white z-50'>
            <div className='flex flex-col gap-6 w-full '>
                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                 ${location.pathname === '/health' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} 
                 to="/health">
                    <MonitorHeartIcon />
                    <span>
                        My Health
                    </span>
                </Link>

                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                 ${location.pathname === '/history' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/history">
                    <AccessTimeIcon />
                    <span>
                        My History
                    </span>
                </Link>
            </div>

            <div className='flex flex-col gap-6 w-full'>
                <Link className={`flex gap-1 items-center w-full p-2 rounded-lg hover:bg-[#CDE9D3] hover:text-[#069125] font-semibold
                ${location.pathname === '/settings' ? 'bg-[#CDE9D3] text-[#069125] font-semibold' : ''}`} to="/settings">
                    <SettingsIcon />
                    <span>
                        Settings
                    </span>
                </Link>

                <button
                    className='flex gap-1 items-center w-full p-2 rounded-lg hover:text-[#E12C2C] hover:font-semibold'
                    onClick={handleLogout}
                >
                    <LogoutIcon />
                    <span>
                        Logout
                    </span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
