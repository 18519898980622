// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../utils/tokenHelper';  // Import your token helper
 
const PrivateRoute = ({ element: Component }) => {
    const token = getToken();

    return token ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
