import React, { useState, useEffect } from 'react';
import Logo from "../../images/logo/Quick-Care.png";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { getToken, clearToken } from '../../utils/tokenHelper';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [profile, setProfile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const token = getToken(); // Get token from localStorage

        if (token) {
            setIsLoggedIn(true);

            // Fetch profile data
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch("https://api.quickcare.ng/api/v1/user/profile", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        setProfile(result.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching profile data:", error);
                });
        }
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleLogout = () => {
        clearToken();
        window.location.reload(); // Reload the page after clearing the token
    };

    const getInitials = () => {
        if (profile) {
            const { first_name, last_name } = profile;
            return `${first_name?.charAt(0)}${last_name?.charAt(0)}`;
        }
        return '';
    };

    return (
        <div className='w-full flex items-center justify-between p-4 md:px-8 lg:px-16'>
            <Link to='/'>
                <img src={Logo} alt="Quick Care Logo" className='w-[150px] lg:w-[200px] h-[auto] mt-2 md:mt-0' />
            </Link>

            <div
                className={`md:flex gap-10 items-center font-medium ${
                    isMenuOpen
                        ? "absolute flex py-4 flex-col gap-1 top-20 left-0 w-full bg-[#069125] text-white"
                        : "hidden"
                }`}
            >
                <a href='#services'>Services</a>
                <a href='#benefits'>Benefits</a>
                <a href='#about-us'>About Us</a>
            </div>

            <div className="flex items-center gap-4">
                {isLoggedIn ? (
                    <>
                        <Link to='/health' className='bg-[#069125] text-white font-medium px-3 py-2 rounded-lg hidden lg:block'>
                            Go to Dashboard
                        </Link>
                        {profile && (
                            <button
                                onClick={toggleModal}
                                className="bg-gray-800 text-white rounded-full w-10 h-10 flex items-center justify-center"
                            >
                                {getInitials()}
                            </button>
                        )}
                    </>
                ) : (
                    <Link to='/signup' className='bg-[#069125] text-white font-medium px-3 py-2 rounded-lg '>
                        Pay with Airtime
                    </Link>
                )}

                <div className='md:hidden p-1 border border-black rounded' onClick={toggleMenu}>
                    {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </div>
            </div>

            {isModalOpen && profile && (
                <div className="absolute top-16 right-8 bg-white shadow-lg p-4 rounded-md z-50">
                    <p className=""><span className='font-medium mr-1'>Name:</span> {`${profile.first_name} ${profile.last_name}`}</p>
                    <p><span className='font-medium mr-1'>Phone no:</span>+{profile.phone_number}</p>
                    <p>
                    <span className='font-medium mr-1'>Subscription Status:{" "}</span>
                        
                        {profile.is_subscribed ? (
                            <span className="text-green-600">Subscribed</span>
                        ) : (
                            <span className="text-red-600">Not Subscribed</span>
                        )}
                    </p>
                    <p className='capitalize'><span className='font-medium mr-1'>Country:</span> {profile.country}</p>
                    <button
                        onClick={handleLogout}
                        className="bg-red-500 text-white mt-2 px-3 py-1 rounded-md"
                    >
                        Logout
                    </button>
                </div>
            )}
        </div>
    );
};

export default Navbar;
